export type IndustryStatistic = {
  countTotal: number;
  countVerified: number;
  countUnverified: number;
  countMicro: number;
  countSmall: number;
  countMedium: number;
  countLarge: number;
  countProducts: number;
};

type GenerateStatisticsOptions = {
  forLanding: Boolean;
};

const useGenerateStatistics = (
  statisticData: IndustryStatistic,
  options?: GenerateStatisticsOptions
) => {
  const { forLanding = false } = options ?? {};

  const {
    countTotal,
    countVerified,
    countUnverified,
    countMicro,
    countSmall,
    countMedium,
    countLarge,
    countProducts
  } = statisticData;

  const statistic = {
    total: {
      icon: 'mdi-clipboard-edit-outline',
      title: `${countTotal} Industri`,
      shortTitle: 'Total Industri',
      value: countTotal,
      text: 'Industri yang telah didata',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4
      }
    },
    verified: {
      icon: 'mdi-clipboard-check-outline',
      title: `${countVerified} Industri`,
      shortTitle: 'Industri Terverifikasi',
      value: countVerified,
      text: 'Yang telah diverifikasi',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4
      }
    },
    unverified: {
      icon: 'mdi-clipboard-text-clock-outline',
      title: `${countUnverified} Industri`,
      shortTitle: 'Industri Belum Diverifikasi',
      value: countUnverified,
      text: 'Yang belum diverifikasi',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4
      }
    },
    // micro: {
    //   icon: 'mdi-office-building-outline',
    //   title: `${countMicro} Industri`,
    //   shortTitle: 'Industri Mikro',
    //   value: countMicro,
    //   text: 'Industri mikro yang terdaftar',
    //   attributes: {
    //     cols: 12,
    //     sm: 6,
    //     md: 4
    //   }
    // },
    small: {
      icon: 'mdi-office-building-outline',
      title: `${countSmall} Industri`,
      shortTitle: 'Industri Kecil',
      value: countSmall,
      text: 'Industri kecil yang terdaftar',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4,
        'offset-md': forLanding ? 1 : 0
      }
    },
    medium: {
      icon: 'mdi-office-building-outline',
      title: `${countMedium} Industri`,
      shortTitle: 'Industri Menengah',
      value: countMedium,
      text: 'Industri menengah yang terdaftar',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4,
        'offset-md': forLanding ? 1 : 0,
        'offset-sm': forLanding ? 3 : 0
      }
    },
    large: {
      icon: 'mdi-office-building-outline',
      title: `${countLarge} Industri`,
      shortTitle: 'Industri Besar',
      value: countLarge,
      text: 'Industri besar yang terdaftar',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4,
        'offset-md': forLanding ? 1 : 0,
        'offset-sm': forLanding ? 3 : 0
      }
    },
    products: {
      icon: 'mdi-cart-arrow-down',
      title: `${countProducts} Produk`,
      shortTitle: 'Total Produk',
      value: countProducts,
      text: 'Produk yang telah didata',
      attributes: {
        cols: 12,
        sm: 6,
        md: 4
      }
    }
  };

  return {
    ...statistic
  };
};

export { useGenerateStatistics };
